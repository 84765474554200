import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/dimensions.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 35, 2, 1 Bl. 276<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1678 (?)<br /><br />
        Translated from the Latin<br /><br />
        Note: text in <s>strikethrough</s> represents Leibniz's own deletions.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (82k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Finding the Dimensions of Figures</title>
            <meta name="description" content="An English translation of ON FINDING THE DIMENSIONS OF FIGURES by Gottfried Wilhelm Leibniz, from 1678 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON FINDING THE DIMENSIONS OF FIGURES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 35, 2, 1 Bl. 276</b>]<br /><br />
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<s>The method for finding the dimension of any figure is twofold, one</s><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To find the dimension of any curve or curvilinear figure is to be able to construct a straight line or a rectilinear figure equal to it, whereby if it is planar it can always be reduced to a square, or if solid, to a cube, which is why the dimension of curvilinear figures is usually called “quadrature”. Sometimes, however, when we can't yet reduce a curvilinear figure to a rectilinear one, we are content with reducing the dimension of one curvilinear figure to that of another curvilinear figure. For example, the quadrature of an ellipse to the quadrature of a circle is obviously simpler. <s>Or we can expand the length of a parabola's curve into a straight line for the quadrature of the hyperbola's area or vice versa; similarly we can expand a spherical surface into a circle equal to it, and transform a sphere into a cylinder.</s> It is also useful to seek the ratio of the parts of a curvilinear figure to each other, or the ratio of different curvilinear figures of the same kind, for example, we know that the sectors of the same circle are to each other as their angles, and that two circles are to each other as squares of their diameters.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The method for doing such things <s>is twofold, one by [1] series [2] transformation</s> requires that the figure be suitably cut into parts (with some added or removed, if needed) and these will be either finite or infinite in number. And the infinite in number are in turn either finite in magnitude or infinitely small in magnitude. And when the section of a figure has been completed mentally, recourse must now be made either to transformations or to series. There will be transformation if the parts into which the figure has been cut are recombined in a different way, resulting a new composite figure that is perhaps easier to measure. However, series will be appropriate if it appears that the parts into which we have divided the figure observe a certain progression, offering the hope that the series of terms observing such a progression can be summed, for then the dimension of the proposed figure will also be obtained. We will explain all these things with easy examples, so that the consequences may be understood more readily.


                <br /><br /><br />
                  &#169; Lloyd Strickland 2024<br />
                  With thanks to Ernesto Diaz for helpful suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
