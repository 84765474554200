import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bouvet.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I volume 14</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 830-834 (selections)<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 12 December 1697<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Joachim Bouvet (12 December 1697)</title>
            <meta name="description" content="An English translation of a LETTER TO JOACHIM BOUVET by Gottfried Wilhelm Leibniz, from 12 December 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOACHIM BOUVET</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A 1 14, 830</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;... The true practical philosophy (the true and not simulated philosophy, as our Roman jurisconsults say) consists in good rules for the education and for the conversation and the socialisation of men, rather than in general precepts on virtues and duties....<br /><br />
              [<b>A I 14, 831</b>]<br /><br />

... I come to physics, and I now include under that name all experimental observations of corporeal things, for which we still cannot explain by geometrical or mechanical principles. Thus they have not been obtained by reason and a priori, but only by experiment and tradition... Medicine ... is the most necessary of the natural sciences. For just as theology is the highest point of knowledge of things that concern the mind, and contains good morals and good politics, it can be said that medicine is likewise the [<b>A I 14, 832</b>] highest point and like the principal fruit of the knowledge of bodies in relation to ours. But every physical science, and even medicine, has for a final goal the glory of God and the supreme happiness of men; for by preserving them it gives them the means to work towards the glory of God...<br /><br />

[<b>A I 14, 833</b>]

...I see that many able people believe that the philosophy of the Schools should be abolished, and an entirely different one substituted in its place, and some want this to be the Cartesian philosophy. But after having weighed everything, I find the philosophy of the ancients is solid, and that we must use the philosophy of the moderns to enrich it and not to destroy it. I have had many disputes about that with able Cartesians, and have shown them by mathematics itself that they do not have the true laws of nature, and that to obtain them we must consider in nature not only matter but also force, and that the forms of the ancients, that is, entelechies, are nothing other than forces. And in that way I believe I have rehabilitated the philosophy of the ancients or of the school, which serves theology so usefully without [<b>A I 14, 834</b>] derogating in any way from modern discoveries or mechanical explanations, since mechanics itself presupposes the consideration of force. And in the phenomena of bodies, it will be found that nothing is more suitable than force to give an opening for the consideration of spiritual causes, and consequently to introduce them to men who are sunk in materialistic notions, as the Chinese doubtless are. Thus I believe I have rendered some service to religion, as much in that as in the fact that I hope it will contribute to stop the course of a philosophy that is overly materialistic from beginning to take hold of minds, in place of which I show that the reasons for the rules of force come from something higher.<br /><br />

        <br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
